const state = () => ({
  appAliveSet: new Set() // 移动端需要 keepAlive的组件名集合
})

const getters = {
  appAliveSet (state) {
    return state.appAliveSet
  }
}

const mutations = {
  addAppAliveItem (state, name) {
    name && typeof name === 'string' && state.appAliveSet.add(name)
  },
  deleteAppAliveItem (state, name) {
    name && typeof name === 'string' && state.appAliveSet.delete(name)
  },
  clearAppAliveSet (state) {
    state.appAliveSet.clear()
  }
}
const actions = { }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
