<template>
  <router-view v-if="!loading" />
  <Loading v-if="loading" />
</template>

<script>
import xbb from 'xbb-sdk'
import { login, getCompanyIndustry } from '@/api/user'
import Loading from '@/components/Loading'
export default {
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.loading = process.env.NODE_ENV !== 'development'
    xbb.getUserInfo().then(async data => {
      const { corpid, userId, xbbAccessToken, env, feeInfo } = data // 钉钉版的 sdk 还没更新，先默认成 prod 保证钉钉版正式环境不会出错
      console.log(857857, corpid)
      console.log('当前环境', env || 'dev')
      this.setUserInfo(corpid, userId, xbbAccessToken, feeInfo)
      const res = await login({ corpid, userId, xbbAccessToken, env: env || 'dev' })
      if (window.XBB_MODULE_CENTER_TYPE !== 'preview') {
        const userIns = localStorage.getItem('userAllIns')
        console.log(userIns)
      }
      if (res && res.data.success) {
        this.loading = false
        localStorage.setItem('token', res.data.result.token)
        // 拿到 token 以后去清除云叩缓存里的 userInfo
        sessionStorage.removeItem('userInfo')
      }
    }).catch(err => {
      if (process.env.NODE_ENV !== 'development') {
        this.$alert('此为站内站点，仅在允许在销帮帮内打开，您如果想预览，请前往预览站点。点击确定将跳转到预览站点', '提示', {
          showClose: false,
          confirmButtonText: '确定',
          callback: _ => {
            window.location.href = '/preview.html'
          }
        })
      } else {
        this.loading = false
      }
      console.error(err)
    })
  },
  methods: {
    setUserInfo (corpid, userId, xbbAccessToken, feeCompany) {
      localStorage.setItem('corpid', corpid)
      localStorage.setItem('userId', userId)
      localStorage.setItem('xbbAccessToken', xbbAccessToken)
      localStorage.setItem('feeCompany', JSON.stringify(feeCompany))
      this.setCompanyIndustry(corpid)
    },
    async setCompanyIndustry (corpid) {
      try {
        const res = await getCompanyIndustry(corpid)
        const CI = res.data.result.industry
        CI && localStorage.setItem('CI', CI)
      } catch (error) {
        // ElMessage.error('获取公司行业失败')
      }
    }
  },
  components: { Loading }
}
</script>
<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
}
.icon {
  width: 1.5em; height: 1.5em;
  vertical-align: -0.4em;
  fill: currentColor;
  overflow: hidden;
  display: inline-block;
}
</style>
