import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import { getIconfont } from './api/base.js'
import '@/styles/index.scss'
import xbb from 'xbb-sdk'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-light.css'

// 引入 tailwindcss
import 'tailwindcss/tailwind.css'

getIconfont().then(res => {
  const path = res.data.result
  const script = document.createElement('script')
  script.src = path
  document.querySelector('head').appendChild(script)
})

try {
  xbb.ready(res => {
    const app = createApp(App)
    app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')
    app.directive('highlight', function (el) {
      const blocks = el.querySelectorAll('pre code')
      blocks.forEach((block) => {
        hljs.highlightBlock(block)
      })
    })
    console.log('ready', res)
    const { shelfVersion, userId, userInfo } = res
    // 埋点
    if (userId) {
      // 上传用户id即可，用户数据已经在gio里面了
      // window.gio('setUserId', userId)
    }
    localStorage.setItem('corpName', userInfo.companyName)
    localStorage.setItem('shelf_version', shelfVersion || 1)
    localStorage.setItem('systemKind', userInfo.systemKind ? String(userInfo.systemKind) : '1')
  })
} catch (e) {
  console.warn('未运行在销帮帮中:', e)
  const app = createApp(App)
  app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')
  app.directive('highlight', function (el) {
    const blocks = el.querySelectorAll('pre code')
    blocks.forEach((block) => {
      hljs.highlightBlock(block)
    })
  })
}
