import {
  createStore
} from 'vuex'
import keepAlive from './modules/keep-alive'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    keepAlive // 管理动态缓存路由状态
  }
})
