import { createRouter, createWebHashHistory } from 'vue-router'
import appPage from './app'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/web/Index.vue'),
    redirect: '/application',
    children: [
      {
        name: 'Application',
        path: 'application',
        component: () => import('@/views/web/pages/application.vue')
      },
      {
        name: 'Solution',
        path: 'solution',
        component: () => import('@/views/web/pages/solution.vue')
      },
      {
        name: 'lowCode',
        path: 'lowCode',
        component: () => import('@/views/web/pages/low-code.vue')
      },
      {
        name: 'Enter',
        path: 'enter',
        component: () => import('@/views/web/pages/module-enter.vue')
      },
      {
        name: 'appDetail',
        path: 'detail/:id',
        component: () => import('@/views/web/pages/application-detail.vue')
      },
      {
        name: 'Search',
        path: 'search-web',
        component: () => import('@/views/web/pages/search.vue')
      }
    ]
  },
  {
    name: 'applyTrial',
    path: '/apply-trial',
    component: () => import('@/views/web/pages/apply-trial.vue')
  },
  {
    name: 'openSuccess',
    path: '/open-success',
    component: () => import('@/views/web/pages/open-success.vue')
  },
  {
    name: 'bannerMarket',
    path: '/banner-market',
    component: () => import('@/views/web/pages/banner-market.vue')
  },
  appPage,
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/error/no-find')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length !== 0) {
    next()
  } else { // 没有匹配跳转 404
    const noFindPath = { name: '404' }
    console.log('from.meta:', from.meta)
    if (from.meta.platform) noFindPath.query = { from: from.meta.platform }
    next(noFindPath)
  }
})

export default router
