import { adminRequest, request } from './request'

export const login = (data) => {
  return request({
    url: '/login',
    method: 'POST',
    data
  })
}

export const getUserForm = () => {
  return request({
    url: '/third-api/get-user-form',
    method: 'GET'
  })
}

export const getCompanyIndustry = () => {
  return request({
    url: '/third-api/get-company-industry',
    method: 'GET'
  })
}
export const previewLogin = (from) => {
  return request({
    url: '/preview-login',
    method: 'POST',
    data: {
      from
    }
  })
}
export const adminLogin = (data) => {
  return adminRequest({
    url: '/admin-login',
    method: 'POST',
    data
  })
}
