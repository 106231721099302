export default {
  path: '/app',
  name: 'App',
  redirect: '/app/index',
  component: () => import('@/views/app/app-layout.vue'),
  children: [
    {
      path: 'index',
      name: 'AppIndex',
      meta: {
        platform: 'app', // 标识当前平台，用于一些共同页面加载时判断
        title: '模板中心'
      },
      component: () => import('@/views/app/Index.vue')
    },
    {
      path: 'detail',
      name: 'AppDetail',
      meta: {
        platform: 'app',
        title: '应用详情',
        keepAlive: true
      },
      component: () => import('@/views/app/detail.vue')
    },
    {
      path: 'more',
      name: 'AppMore',
      meta: {
        platform: 'app',
        title: '应用列表'
      },
      component: () => import('@/views/app/more.vue')
    },
    {
      path: 'search',
      name: 'AppSearch',
      meta: {
        platform: 'app',
        title: '搜索',
        keepAlive: true
      },
      component: () => import('@/views/app/search.vue')
    },
    {
      path: 'settle-apply',
      name: 'SettleApply',
      meta: {
        platform: 'app',
        title: '入驻申请'
      },
      component: () => import('@/views/app/settle-apply.vue')
    }
  ]
}
