import Axios from 'axios'
const request = Axios.create({
  baseURL: process.env.VUE_APP_BACKEND_HOST,
  timeout: 10000
})

request.interceptors.request.use((config) => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }

  const defaultParams = {
    corpid: localStorage.getItem('corpid'),
    userId: localStorage.getItem('userId'),
    xbbAccessToken: localStorage.getItem('xbbAccessToken')
  }
  config.params = { ...defaultParams, ...config.params }
  return config
})

request.interceptors.response.use((response) => {
  return response
})

// 暂时建两个axios对象，对于admin的请求不需要corpid、userId、xbbAccessToken
// 后边再调整更优雅的方式
const adminRequest = Axios.create({
  // baseURL: 'http://192.168.20.70:7001' || '',
  baseURL: process.env.VUE_APP_BACKEND_HOST,
  timeout: 10000
})
adminRequest.interceptors.request.use((config) => {
  if (localStorage.getItem('adminToken')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('adminToken')}`
  }
  return config
})

const requestMap = (...params) => {
  const curLocation = window.location
  return curLocation.pathname && curLocation.pathname !== '/' && curLocation.pathname !== '/preview.html' ? adminRequest(...params) : request(...params)
}

export {
  request,
  adminRequest,
  requestMap
}
